import { TranslateService } from '@ngx-translate/core';
import { LoginService } from './../core/service/login.service';
import { UserContextService } from './../core/service/user-context.service';
import { AfterViewInit, Component, HostListener, OnInit, ViewChild, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { HeaderDesktopComponent } from './header-desktop/header-desktop.component';
import { HeaderMobileComponent } from './header-mobile/header-mobile.component';
import { SideBarDesktopComponent } from './sidebar-desktop/sidebar-desktop.component';
import { NgStyle } from '@angular/common';
import { ProfileSettingsModalComponent } from './profile-settings-modal/profile-settings-modal.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@Component({
  selector: 'lgfg-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss'],
  imports: [HeaderDesktopComponent, HeaderMobileComponent, RouterOutlet,
    SideBarDesktopComponent, NgStyle, ProfileSettingsModalComponent, NgxSpinnerModule]
})

export class LayoutComponent implements OnInit, AfterViewInit {
  private loginService = inject(LoginService);
  private userContextService = inject(UserContextService);

  @ViewChild('profileSettingsModal', { static: true }) profileSettingsModal: ProfileSettingsModalComponent;

  public isAuthorize = true;
  public mobile = false;
  public eventLocker = false;
  public bodyMaxWidth: number;
  public isOpenSideBar = false;
  public loaderImg = "<img src='assets/crown.png'/>";

  public constructor() {
    const translate = inject(TranslateService);

    translate.use("en");
  }

  public ngAfterViewInit(): void {
    setTimeout(() => {
      this.mobile = window.outerWidth < 1024;
    }, 500);
  }

  public onOpenSideBar(isOpenSideBar: boolean = null) {
    if (!this.isAuthorize) {
      this.bodyMaxWidth = -1;
      return;
    }

    this.isOpenSideBar = isOpenSideBar == null ? this.isOpenSideBar : isOpenSideBar;
    if (this.isOpenSideBar) {
      this.bodyMaxWidth = window.innerWidth - 269;
    }
    else {
      this.bodyMaxWidth = window.innerWidth - 107;
    }
  }

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.onOpenSideBar();
  }

  public async ngOnInit(): Promise<void> {
    await this.loginService.setUserContext();
    this.userContextService.isAuthorize.subscribe(x => {
      this.isAuthorize = x;
      if (!x) {
        this.bodyMaxWidth = -1;
      }
    });
    this.mobile = window.outerWidth < 1024;
  }
}

