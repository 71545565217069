import { LastSalesNotificationModel } from '../../core/models/notification/last-salse-notification.model';
import {
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener, OnInit, Signal, ViewChild,
    WritableSignal, computed, inject, input, signal
} from '@angular/core';
import { CommonModule, NgClass } from '@angular/common';
import { GlobalConstants } from 'src/app/core/global-constants';
import { UserContextService } from 'src/app/core/service/user-context.service';
import { NotificationService } from 'src/app/core/service/notification.service';
import { LastSurveyNotificationModel } from 'src/app/core/models/notification/last-survey-notification.model';
import { BaseNotificationModel } from 'src/app/core/models/notification/base-notification.model';
import { ArrayUtil } from 'src/app/core/utility/array.util';

@Component({
    selector: 'notification',
    templateUrl: './notification.component.html',
    styleUrls: ['./notification.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [CommonModule, NgClass]
})

export class NotificationComponent implements OnInit {
    private service = inject(NotificationService);
    private userContextService = inject(UserContextService);
    private ref = inject(ChangeDetectorRef);

    public readonly isMobile = input<boolean>(false);

    @ViewChild("dropdownBody", { static: true }) public dropdownBody: any;

    public notifications: WritableSignal<LastSalesNotificationModel[] | LastSurveyNotificationModel[]> = signal([]);
    public lastNotification: Signal<LastSalesNotificationModel[] | LastSurveyNotificationModel[]> =
        computed(() => ArrayUtil.orderByDesc(this.notifications() as any, "eventDate")) as any;

    public missingNotificationsCount: WritableSignal<number> = signal(0);
    public missingNotifications: Signal<string> = computed(() =>
        this.missingNotificationsCount() > 9 ? "9+" : this.missingNotificationsCount().toString());

    public isOpen: boolean = false;
    public isDarkTheme: boolean = false;

    async ngOnInit(): Promise<void> {
        this.notifications.set((await this.service.getLastSales()).concat(await this.service.getSurveyNotifications() as any));
        this.setMissingNotifications();

        this.userContextService.themeState.subscribe((x) => {
            this.isDarkTheme = x;
            this.ref.detectChanges();
        });
        setInterval(async () => {
            this.notifications.set((await this.service.getLastSales()).concat(await this.service.getSurveyNotifications() as any));
            this.setMissingNotifications();
        }, 60000);
    }

    private setMissingNotifications(): void {
        const storedNotifications: BaseNotificationModel[] = JSON.parse(localStorage.getItem(GlobalConstants.viewedNotificationsKey));
        if (!storedNotifications || storedNotifications.length == 0) {
            this.missingNotificationsCount.set(this.notifications().length);
        }
        else {
            this.missingNotificationsCount.set(this.notifications()
                .filter(x => storedNotifications.every(y => y.id != x.id)).length);
        }
        this.ref.detectChanges();
    }

    public toggle(): void {
        setTimeout(() => {
            if (!this.isOpen) {
                this.missingNotificationsCount.set(0);
                localStorage.setItem(GlobalConstants.viewedNotificationsKey, JSON.stringify(this.lastNotification()));
            }
            this.isOpen = !this.isOpen;
            this.ref.detectChanges();
        }, 70);
    }

    @HostListener('document:click', ['$event.target'])
    public onClick(targetElement: ElementRef) {
        if (!this.isOpen) {
            return;
        }
        const clickedInside = this.dropdownBody.nativeElement.contains(targetElement) ||
            document.getElementsByClassName('icon-body')[0].contains(targetElement as any)
        if (!clickedInside) {
            this.isOpen = false;
            this.ref.detectChanges();
        }
    }

    public identify(index, item) {
        return item.orderId;
    }
}
