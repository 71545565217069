import { Component, EventEmitter, ViewChild } from "@angular/core";
import { ModalComponent } from "src/app/shared/modal/modal.component";

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    imports: [ModalComponent]
})
export class ConfirmModalComponent {
    @ViewChild("modal") modal: ModalComponent;
    public complete: EventEmitter<boolean> = new EventEmitter<boolean>();
    public bloker = false;
    public title: string;
    public text: string;

    public async submit(): Promise<void> {
        if (this.bloker) {
            return;
        }
        this.bloker = true;
        this.complete.emit(true);
        this.bloker = false;
        this.complete = new EventEmitter<boolean>();
        this.modal.close();
    }

    public async open(title: string, text: string): Promise<void> {
        this.title = title;
        this.text = text;
        this.modal.open();
    }

    public async openConfirm(comfirm: IConfirmation): Promise<void> {
        this.title = comfirm.header;
        this.text = comfirm.message;
        this.modal.open();
    }

    public resetModal() {
        this.bloker = false;
        this.complete.emit(false);
        this.complete = new EventEmitter<boolean>();
        this.modal.close();
    }
}
