import { BaseReportGuard } from './core/guard/base-report.guard';



import { Routes } from '@angular/router';
import { links } from './core/links';


import { QuizService } from './core/service/quiz.service';
import { QuizGuard } from './core/guard/quiz.guard';

export const ROUTES: Routes = [
  {
    path: links.reportLink,
    loadChildren: () => import('./reports/report.routes').then(r => r.ROUTES)
  },
  {
    path: links.submitPaymentLink,
    loadChildren: () => import('./submit-payment/submit-payment.routes').then(r => r.ROUTES)
  },
  {
    path: links.surveyResultLink,
    loadChildren: () => import('./survey-result/survey-result.routes').then(r => r.ROUTES)
  },
  {
    path: links.documentsLink,
    loadChildren: () => import('./documents/document.routes').then(r => r.ROUTES)
  },
  {
    path: links.appointmentCardLink,
    loadChildren: () => import('./appointment-card/appointment-card.routes').then(r => r.ROUTES)
  },
  {
    path: links.stratPage,
    loadComponent: () => import('./start-page/start-page.component').then(m => m.StartPageComponent)
  },
  {
    path: links.loginLink,
    loadChildren: () => import('./login/login.routes').then(r => r.ROUTES)
  },
  {
    path: links.errors.error403,
    loadComponent: () => import('./error-pages/access-denied/access-denied.component').then(m => m.AccessDeniedPageComponent)
  },
  {
    path: links.errors.error404,
    loadComponent: () => import('./error-pages/notfound/notfound.component').then(m => m.NotFoundPageComponent)
  },
  {
    path: links.adminSettingsLink,
    loadChildren: () => import('./admin-settings/admin-settings.routes').then(r => r.ROUTES)
  },
  {
    path: links.myGoal,
    loadComponent: () => import('./my-goal-page/my-goal-page.component').then(m => m.MyGoalPageComponent),
    canActivate: [BaseReportGuard]
  },
  {
    path: links.quizLink,
    loadComponent: () => import('./quiz/quiz.component').then(m => m.QuizComponent),
    providers: [QuizService],
    canActivate: [QuizGuard]
  },
  {
    path: links.wardrobeCardsLink,
    loadChildren: () => import('./wardrobe-cards/wardrobe-cards.routes').then(r => r.ROUTES)
  }
];
