@if(missingNotifications() != "0")
{
<div (click)="toggle()" class="icon-body" [ngClass]="isOpen ? 'active':''">
    <svg id="notyfication-svg" style="margin: auto;" width="24.250000" height="20.250000" viewBox="0 0 24.25 20.25"
        fill="none" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <clipPath id="clip8_9334">
                <rect id="Bell" width="19.000000" height="19.000000" transform="translate(0.500000 0.750000)"
                    fill="white" fill-opacity="0" />
            </clipPath>
        </defs>
        <rect id="Bell" width="19.000000" height="19.000000" transform="translate(0.500000 0.750000)" fill="#FFFFFF"
            fill-opacity="0" />
        <g clip-path="url(#clip8_9334)">
            <path id="Vector"
                d="M17.82 14.34C16.56 13.27 15.84 11.72 15.84 10.07L15.84 7.75C15.84 4.81 13.66 2.39 10.84 1.98L10.84 1.08C10.84 0.62 10.47 0.25 10.01 0.25C9.55 0.25 9.17 0.62 9.17 1.08L9.17 1.98C6.35 2.39 4.17 4.81 4.17 7.75L4.17 10.07C4.17 11.72 3.45 13.27 2.18 14.35C1.86 14.62 1.67 15.03 1.67 15.45C1.67 16.26 2.33 16.91 3.13 16.91L16.88 16.91C17.69 16.91 18.34 16.26 18.34 15.45C18.34 15.03 18.16 14.62 17.82 14.34Z"
                fill="#BDBDBD" fill-opacity="1.000000" fill-rule="nonzero" />
            <path id="Vector"
                d="M10.01 20.25C11.52 20.25 12.78 19.17 13.07 17.75L6.95 17.75C7.24 19.17 8.5 20.25 10.01 20.25Z"
                fill="#BDBDBD" fill-opacity="1.000000" fill-rule="nonzero" />
        </g>
        <circle id="Ellipse 14" cx="18.000000" cy="6.250000" r="6.250000" fill="#845AE0" fill-opacity="1.000000" />
    </svg>
    <div id="notyfication-counter" class="counter">{{missingNotifications()}}</div>
</div>
}
@else {
<div (click)="toggle()" class="icon-body" [ngClass]="isOpen ? 'active':''">
    <svg style="margin: auto;" width="20.000000" height="20.000000" viewBox="0 0 20 20" fill="none"
        xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <defs>
            <clipPath id="clip8_12694">
                <rect id="Bell" width="19.000000" height="19.000000" transform="translate(0.500000 0.500000)"
                    fill="white" fill-opacity="0" />
            </clipPath>
        </defs>
        <rect id="Bell" width="19.000000" height="19.000000" transform="translate(0.500000 0.500000)" fill="#FFFFFF"
            fill-opacity="0" />
        <g clip-path="url(#clip8_12694)">
            <path id="Vector"
                d="M17.82 14.09C16.56 13.02 15.84 11.47 15.84 9.82L15.84 7.5C15.84 4.56 13.66 2.14 10.84 1.73L10.84 0.83C10.84 0.37 10.47 0 10.01 0C9.55 0 9.17 0.37 9.17 0.83L9.17 1.73C6.35 2.14 4.17 4.56 4.17 7.5L4.17 9.82C4.17 11.47 3.45 13.02 2.18 14.1C1.86 14.37 1.67 14.78 1.67 15.2C1.67 16.01 2.33 16.66 3.13 16.66L16.88 16.66C17.69 16.66 18.34 16.01 18.34 15.2C18.34 14.78 18.16 14.37 17.82 14.09Z"
                fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
            <path id="Vector" d="M10.01 20C11.52 20 12.78 18.92 13.07 17.5L6.95 17.5C7.24 18.92 8.5 20 10.01 20Z"
                fill="#FFFFFF" fill-opacity="1.000000" fill-rule="nonzero" />
        </g>
    </svg>
</div>
}
<div #dropdownBody>
    @if(isOpen)
    {
    <div [ngClass]="{'mobile' : isMobile(),'light-theme' : !isDarkTheme}">
        <div class="dropdown">
            <div class="notifications-header">Notifications</div>
            <div class="notification-list">
                @for (item of lastNotification(); track identify($index, item)) {
                @if(item.type == 1)
                {
                <div style="margin-top: 25px;">
                    <div style="width: 100%;"><span style="font-weight: 700;">Clothier: </span>{{item.rep}}</div>
                    <div style="width: 100%;"><span style="font-weight: 700;">Client: </span>{{item.client}}</div>
                    <div style="width: 100%;"><span style="font-weight: 700;">Clothier - Professionalism, Knowledge,
                            etc.: </span>{{item.repRate}}</div>
                    <div style="width: 100%;"><span style="font-weight: 700;">Experience with Your LGFG Product so far:

                        </span>{{item.lgfgRate}}</div>
                    <div style="width: 100%;"><span style="font-weight: 700;">Feedback: </span>{{item.feedback}}</div>
                </div>
                }
                @if(item.type == 2)
                {
                <div class="notification-item">
                    <div style="width: 100%;"><span style="font-weight: 700;">{{item.rep}},</span> {{item.city}}</div>
                    <div class="notification-item-value">${{item.value}}</div>
                </div>
                }
                }
            </div>
        </div>
    </div>
    }
</div>