import { remove } from 'lodash-es/remove';
import { UserContextService } from 'src/app/core/service/user-context.service';
import { UserService } from './../../core/service/user.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, ViewChild, inject } from "@angular/core";
import { Subject } from 'rxjs';
import { FormsModule } from '@angular/forms';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { InputComponent } from 'src/app/shared/form/Input/input.component';
import { FileSelectorComponent } from 'src/app/shared/form/file-selector/file-selector';
import { FileFormatValidator } from 'src/app/shared/form/validators/file-format-validator.directive';
import { UploadService } from 'src/app/core/service/upload.service';
import { CloudInaryFolders } from 'src/app/core/enums/cloudinady-folders.enum';
import { LoginService } from 'src/app/core/service/login.service';
import { UserModel } from 'src/app/core/models/user.model';
import { ControlContainerDirective } from 'src/app/shared/form/form-control-container/control-container.directive';
import { ControlAccessDirective } from 'src/app/shared/form/form-control-container/control-access.directive';
import { NgClass } from '@angular/common';
import { GlobalConstants } from 'src/app/core/global-constants';

@Component({
    selector: 'profile-settings-modal',
    templateUrl: './profile-settings-modal.component.html',
    styleUrls: ['./profile-settings-modal.component.scss'],
    imports: [
        NgClass,
        ModalComponent,
        FormsModule,
        InputComponent,
        FileSelectorComponent,
        FileFormatValidator,
        ControlAccessDirective,
        ControlContainerDirective,
    ]
})
export class ProfileSettingsModalComponent {
    private spinner = inject(NgxSpinnerService);
    private userService = inject(UserService);
    private uploadService = inject(UploadService);
    private userContextService = inject(UserContextService);
    private loginService = inject(LoginService);

    @ViewChild("modal") modal: ModalComponent;
    @ViewChild("AvatarContainer", { static: true }) private avatarContainer: ControlContainerDirective;
    @ViewChild("PasswordContainer", { static: true }) private passwordContainer: ControlContainerDirective;

    public reset = new Subject();
    public isProcessing = false;
    public password: string;
    public file: File;
    public user: UserModel;
    public isDarkTheme: boolean;
    public isFileSending = false;
    public imageFileTypes = GlobalConstants.imageFileTypes;

    constructor() {
        const userContextService = this.userContextService;

        userContextService.themeState.subscribe((x) => {
            this.isDarkTheme = x;
        });
    }

    public open() {
        this.user = this.userContextService.user.value;
        this.spinner.hide();
        this.modal.open();
    }

    public async updatePassword(): Promise<void> {
        if (!this.passwordContainer.validate()) {
            return;
        }
        this.isProcessing = true;
        this.spinner.show();
        try {
            await this.userService.updatePassword(this.password);
        }
        finally {
            this.isProcessing = false;
            this.spinner.hide();
        }
        this.resetModal();
    }

    public async updateAvatar(): Promise<void> {
        if (!this.avatarContainer.validate() || this.isFileSending) {
            return;
        }
        this.isProcessing = true;
        this.spinner.show();
        this.isFileSending = true;
        try {
            var fileId = await this.uploadService.upload(this.file, CloudInaryFolders.UserAvatar);
            await this.userService.updateAvatar(fileId);
            this.user = await this.loginService.getContext(this.user.id);
            this.userContextService.setUser(this.user);
            this.userContextService.avatarChange.next();
        }
        finally {
            this.isProcessing = false;
            this.spinner.hide();
            this.isFileSending = false;
        }
        this.resetModal();
    }

    public async removeAvatar(): Promise<void> {
        this.isProcessing = true;
        this.spinner.show();
        try {
            await this.userService.deleteAvatar();
            this.user = await this.loginService.getContext(this.user.id);
            this.userContextService.setUser(this.user);
            this.userContextService.avatarChange.next();
        }
        finally {
            this.isProcessing = false;
            this.spinner.hide();
        }
        this.file = null;
    }

    public resetModal() {
        this.avatarContainer.markAsPristine();
        this.passwordContainer.markAsPristine();
        this.password = '';
        this.file = null;
        this.reset.next(false);
        this.modal.close();
    }
}
